import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import * as Sentry from '@sentry/angular-ivy';

import { Toast } from '@shared/models/toast.model';
import { AlertifyService } from '@shared/services';

import { ToastComponent } from '../toast/toast.component';

@Component({
  selector: 'app-toast-manager',
  templateUrl: './toast-manager.component.html',
  styleUrls: ['./toast-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ToastComponent],
})
export class ToastManagerComponent implements OnInit {
  toasts: Toast[] = [];

  private readonly alertifyService = inject(AlertifyService);

  private readonly cdr = inject(ChangeDetectorRef);
  private readonly destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.alertifyService.toasts$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((toast) => {
      if (toast.message) {
        this.addToast(toast);
      } else {
        this.logNoMessageToast(toast);
      }
    });
  }

  dismissToast(id: Toast['id']): void {
    this.toasts = this.toasts.filter((toast) => toast.id !== id);
    this.cdr.markForCheck();
  }

  private addToast(toast: Toast): void {
    this.toasts = [...this.toasts, toast];
    this.cdr.markForCheck();
  }

  private logNoMessageToast(toast: Toast): void {
    Sentry.captureException(new Error(`EMPTY MESSAGE TOAST: ${toast.type}`));
  }
}
