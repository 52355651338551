import { appAiAssistIcon } from './ai-assist';
import { appAppInstallIcon } from './app-install';
import { appAppUninstallIcon } from './app-uninstall';
import { appAscendIcon } from './ascend';
import { appCondenseIcon } from './condense';
import { appDefaultIcon } from './default';
import { appDescendIcon } from './descend';
import { appDetachIcon } from './detach';
import { appDisableIcon } from './disable';
import { appExclamationMarkIcon } from './exclamation-mark';
import { appExpandIcon } from './expand';
import { appInfoMarkIcon } from './info-mark';
import { appLevelAIcon } from './level-a';
import { appLevelBIcon } from './level-b';
import { appLevelCIcon } from './level-c';
import { appLevelDIcon } from './level-d';
import { appLevelEIcon } from './level-e';
import { appLoadingIcon } from './loading';
import { appManagerIcon } from './manager';
import { appMinimizeIcon } from './minimize';
import { appOverviewIcon } from './overview';
import { appPartnerIcon } from './partner';
import { appRamIcon } from './ram';
import { appShelveIcon } from './shelve';
import { appSortingIcon } from './sorting';
import { appTaikunIcon } from './taikun';
import { appTerminalBrowserIcon } from './terminal-browser';
import { appTerminalIcon } from './terminal';
import { appUnshelveIcon } from './unshelve';
import { appXCloseIcon } from './x-close';
export const taikunIcons = [appAiAssistIcon, appAppInstallIcon, appAppUninstallIcon, appAscendIcon, appCondenseIcon, appDefaultIcon, appDescendIcon, appDetachIcon, appDisableIcon, appExclamationMarkIcon, appExpandIcon, appInfoMarkIcon, appLevelAIcon, appLevelBIcon, appLevelCIcon, appLevelDIcon, appLevelEIcon, appLoadingIcon, appManagerIcon, appMinimizeIcon, appOverviewIcon, appPartnerIcon, appRamIcon, appShelveIcon, appSortingIcon, appTaikunIcon, appTerminalBrowserIcon, appTerminalIcon, appUnshelveIcon, appXCloseIcon];
