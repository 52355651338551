import { Routes } from '@angular/router';

import { UserRole } from '@api-open';
import { ProjectDtoService } from '@tk/entities/project/details';
import {
  roleGuard,
  userPermissionGuard,
  authGuard,
  hasDataForAuthedZone,
  nonAuthGuard,
  permissions,
} from '@tk/shared/auth';

import { organizationsResolver } from './shared/resolvers';

export const appRoutes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: '',
    canActivate: [authGuard, hasDataForAuthedZone],
    resolve: {
      organizations: organizationsResolver,
    },
    loadComponent: () => import('./layouts/main/main.component').then((c) => c.MainComponent),
    children: [
      {
        path: 'apps',
        loadChildren: () => import('./modules/applications/applications.module').then((m) => m.ApplicationsModule),
        canActivate: [authGuard, userPermissionGuard],
        data: {
          permissions: permissions.applications,
        },
      },
      {
        path: 'projects',
        title: 'Projects',
        loadChildren: () => import('@tk/pages/project/projects.module').then((m) => m.ProjectsModule),
        canActivate: [authGuard, userPermissionGuard],
        data: {
          permissions: permissions.projects,
        },
        providers: [ProjectDtoService],
      },
      // {
      //   path: 'project-groups',
      //   title: 'Project Groups',
      //   loadChildren: () => import('./pages/project-collections').then((m) => m.ProjectCollectionsModule),
      //   canActivate: [authGuard, userPermissionGuard],
      //   data: {
      //     permissions: permissions.projectGroups,
      //   },
      // },
      {
        path: 'home',
        title: 'Dashboard',
        loadChildren: () => import('./pages/home/home.module').then((m) => m.HomeModule),
        canActivate: [authGuard, userPermissionGuard],
        data: {
          permissions: permissions.home,
        },
      },
      {
        path: 'organizations',
        title: 'Organizations',
        loadChildren: () => import('./pages/organizations/organizations.module').then((m) => m.OrganizationsModule),
        canActivate: [authGuard, userPermissionGuard],
        data: { permissions: permissions.organizations },
      },
      {
        path: 'user-info',
        redirectTo: 'home',
      },
      {
        path: 'project-quotas',
        title: 'Project quotas',
        loadChildren: () => import('./pages/project-quotas/project-quotas.module').then((m) => m.ProjectQuotasModule),
        canActivate: [authGuard, userPermissionGuard],
        data: {
          permissions: permissions.projectQuotas,
        },
      },
      {
        path: 'servers',
        title: 'Servers',
        loadChildren: () => import('./pages/servers/servers.module').then((m) => m.ServerForAdminModule),
        canActivate: [authGuard, userPermissionGuard],
        data: { permissions: permissions.servers },
      },
      {
        path: 'flavor-info',
        title: 'Flavor information',
        loadChildren: () => import('./pages/flavors-info/flavors-info.module').then((m) => m.FlavorsInfoModule),
        canActivate: [authGuard, userPermissionGuard],
        data: {
          permissions: permissions.flavorInfo,
        },
      },
      {
        path: 'audit-log',
        title: 'Audit log',
        loadChildren: () => import('./pages/history/history.module').then((m) => m.HistoryModule),
        canActivate: [authGuard, userPermissionGuard],
        data: { permissions: permissions.auditLog },
      },
      {
        path: 'users',
        title: 'Users',
        loadChildren: () => import('./pages/users/users.module').then((m) => m.UsersModule),
        canActivate: [authGuard, userPermissionGuard],
        data: {
          permissions: permissions.users,
        },
      },
      // {
      //   path: 'user-groups',
      //   title: 'User Groups',
      //   loadChildren: () => import('./pages/user-group/user-group.module').then((m) => m.UserCollectionsModule),
      //   canActivate: [authGuard, userPermissionGuard],
      //   data: { permissions: permissions.userGroups },
      // },
      {
        path: 'kubespray',
        title: 'Kubespray',
        loadChildren: () => import('./pages/kubespray/kubespray.module').then((m) => m.KubesprayModule),
        canActivate: [authGuard, userPermissionGuard],
        data: {
          permissions: permissions.kubespray,
        },
      },
      {
        path: 'infra-cost/:projectId',
        title: 'Project infrastructure cost',
        loadChildren: () => import('./pages/infra-cost/infra-cost.module').then((m) => m.InfraCostModule),
        canActivate: [authGuard],
        data: {
          documentationKey: 'infra-cost',
        },
      },
      {
        path: 'ai-credentials',
        title: 'AI credentials',
        loadChildren: () => import('./pages/ai-credentials/ai-credentials.module').then((m) => m.AiCredentialsModule),
        canActivate: [authGuard, userPermissionGuard],
        data: { permissions: permissions.aiCredentials },
      },
      {
        path: 'cloud-credentials',
        title: 'Cloud credentials',
        loadChildren: () =>
          import('./pages/cloud-credentials/cloud-credentials.module').then((m) => m.CloudCredentialsModule),
        canActivate: [authGuard, userPermissionGuard],
        data: { permissions: permissions.cloudCredentials },
      },
      {
        path: 'backup-credentials',
        title: 'Backup credentials',
        loadChildren: () =>
          import('./pages/backup-credentials/backup-credentials.module').then((m) => m.BackupCredentialsModule),
        canActivate: [authGuard, userPermissionGuard],
        data: { permissions: permissions.backupCredentials },
      },
      {
        path: 'access-profiles',
        title: 'Access profiles',
        loadChildren: () =>
          import('./pages/access-profiles/access-profiles.module').then((m) => m.AccessProfilesModule),
        canActivate: [authGuard, userPermissionGuard],
        data: {
          permissions: permissions.accessProfiles,
        },
      },
      {
        path: 'billing-credentials',
        title: 'Billing credentials',
        loadChildren: () =>
          import('./pages/billing-credentials/billing-credentials.module').then((m) => m.BillingCredentialsModule),
        canActivate: [authGuard, userPermissionGuard],
        data: { permissions: permissions.billingCredentials },
      },
      {
        path: 'usage-reports',
        title: 'Usage reports',
        loadChildren: () => import('./pages/usage-reports/usage-reports.module').then((m) => m.UsageReportsModule),
        canActivate: [authGuard, userPermissionGuard],
        data: {
          permissions: permissions.usageReports,
        },
      },
      {
        path: 'chargeback',
        title: 'Chargeback',
        loadChildren: () =>
          import('./pages/prometheus-billing-summary/prometheus-billing-summary.module').then(
            (m) => m.PrometheusBillingSummaryModule,
          ),
        canActivate: [authGuard, userPermissionGuard],
        data: {
          permissions: permissions.chargeback,
        },
      },
      {
        path: 'showback-credentials',
        title: 'Showback credentials',
        loadChildren: () =>
          import('./pages/showback-credentials/showback-credentials.module').then((m) => m.ShowbackCredentialsModule),
        canActivate: [authGuard, userPermissionGuard],
        data: { permissions: permissions.showbackCredentials },
      },
      {
        path: 'showback-rules',
        title: 'Showback rules',
        loadChildren: () => import('./pages/showback-rules/showback-rules.module').then((m) => m.ShowbackRulesModule),
        canActivate: [authGuard, userPermissionGuard],
        data: { permissions: permissions.showbackRules },
      },
      {
        path: 'showback-summary',
        title: 'Showback summary',
        loadChildren: () =>
          import('./pages/showback-summary/showback-summary.module').then((m) => m.ShowbackSummaryModule),
        canActivate: [authGuard, userPermissionGuard],
        data: { permissions: permissions.showbackSummary },
      },
      {
        path: 'partners',
        title: 'Partners',
        loadChildren: () => import('./pages/partners/partners.module').then((m) => m.PartnersModule),
        canActivate: [authGuard, userPermissionGuard],
        data: { permissions: permissions.partners },
      },
      {
        path: 'billing-rules',
        title: 'Billing rules',
        loadChildren: () => import('./pages/prometheus/prometheus.module').then((m) => m.PrometheusModule),
        canActivate: [authGuard, userPermissionGuard],
        data: { permissions: permissions.billingRules },
      },
      {
        path: 'logs/:projectId',
        title: 'Logs',
        loadChildren: () => import('./pages/loki-logs/loki-logs.module').then((m) => m.LokiLogsModule),
        canActivate: [authGuard, roleGuard],
        data: {
          requiredRoles: [UserRole.admin, UserRole.partner, UserRole.manager, UserRole.user],
          documentationKey: 'logs',
        },
      },
      {
        path: 'prometheus-metrics/:projectId',
        title: 'Prometheus metrics',
        loadChildren: () =>
          import('./pages/prometheus-metrics/prometheus-metrics.module').then((m) => m.PrometheusMetricsModule),
        canActivate: [authGuard, roleGuard],
        data: {
          requiredRoles: [UserRole.admin, UserRole.partner, UserRole.manager, UserRole.user],
          documentationKey: 'prometheus-metrics',
        },
      },
      {
        path: 'flavor-project-bounds',
        title: 'Flavor project bounds',
        loadChildren: () =>
          import('./pages/flavor-project-bounds/flavor-project-bounds.module').then((m) => m.FlavorProjectBoundsModule),
        canActivate: [authGuard, roleGuard],
        data: {
          requiredRoles: [UserRole.admin, UserRole.partner, UserRole.manager],
        },
      },
      {
        path: 'kubernetes-profiles',
        title: 'Kubernetes profiles',
        loadChildren: () =>
          import('./pages/kubernetes-profiles/kubernetes-profiles.module').then((m) => m.KubernetesProfilesModule),
        canActivate: [authGuard, userPermissionGuard],
        data: {
          permissions: permissions.kubernetesProfiles,
        },
      },
      {
        path: 'slack-configuration',
        title: 'Slack configuration',
        loadChildren: () =>
          import('./pages/slack-configuration/slack-configuration.module').then((m) => m.SlackConfigurationModule),
        canActivate: [authGuard, userPermissionGuard],
        data: { permissions: permissions.slackConfiguration },
      },
      {
        path: 'payments',
        title: 'Payments',
        loadChildren: () => import('./pages/payments/payments.module').then((m) => m.PaymentsModule),
        canActivate: [authGuard, userPermissionGuard],
        data: {
          permissions: permissions.payments,
        },
      },
      {
        path: 'subscription',
        title: 'Subscriptions',
        loadChildren: () => import('./pages/subscriptions/subscriptions.module').then((m) => m.SubscriptionsModule),
        canActivate: [authGuard, userPermissionGuard],
        data: { permissions: permissions.subscription },
      },
      {
        path: 'infra-details',
        title: 'taikun.infra',
        loadComponent: () => import('./pages/infra-details').then((c) => c.InfraDetailsComponent),
        canActivate: [authGuard, userPermissionGuard],
        data: { permissions: permissions.infraDetails },
      },
      {
        path: 'alerting-profiles',
        title: 'Alerting profiles',
        loadChildren: () =>
          import('./pages/alerting-profiles/alerting-profiles.module').then((m) => m.AlertingProfilesModule),
        canActivate: [authGuard, userPermissionGuard],
        data: { permissions: permissions.alertingProfiles },
      },
      {
        path: 'admin-control-panel',
        title: 'Admin control panel',
        loadChildren: () =>
          import('./pages/admin-control-panel/admin-control-panel.module').then((m) => m.AdminControlPanelModule),
        canActivate: [authGuard, userPermissionGuard],
        data: { permissions: permissions.adminControlPanel },
      },
      {
        path: 'taikun-requests',
        title: 'Taikun requests',
        loadChildren: () => import('./pages/taikun-request/taikun-request.module').then((m) => m.TaikunRequestModule),
        canActivate: [authGuard, userPermissionGuard],
        data: { permissions: permissions.taikunRequests },
      },
      {
        path: 'ticketing',
        title: 'Ticketing',
        loadChildren: () => import('./pages/ticketing/ticketing.module').then((m) => m.TicketingModule),
        canActivate: [authGuard, userPermissionGuard],
        data: { permissions: permissions.ticketing },
      },
      {
        path: 'alerts',
        title: 'Alerts',
        loadChildren: () => import('./pages/alerts/alerts.module').then((m) => m.AlertsModule),
        canActivate: [authGuard, roleGuard],
        data: {
          requiredRoles: [UserRole.admin, UserRole.manager, UserRole.partner],
        },
      },
      {
        path: 'prometheus-metrics-dashboard/:projectId',
        title: 'Prometheus metrics dashboard',
        loadChildren: () =>
          import('./pages/prometheus-metrics-dashboard/prometheus-metrics-dashboard.module').then(
            (m) => m.PrometheusMetricsDashboardModule,
          ),
        canActivate: [authGuard, roleGuard],
        data: {
          requiredRoles: [UserRole.admin, UserRole.manager, UserRole.partner, UserRole.user],
        },
      },
      {
        path: 'policy-profiles',
        title: 'Policy profiles',
        loadChildren: () => import('./pages/opa-profiles/opa-profiles.module').then((m) => m.OpaProfilesModule),
        canActivate: [authGuard, userPermissionGuard],
        data: { permissions: permissions.policyProfiles },
      },
      {
        path: 'standalone-profiles',
        title: 'Standalone profiles',
        loadChildren: () =>
          import('./pages/standalone-profiles/standalone-profiles.module').then((m) => m.StandaloneProfilesModule),
        canActivate: [authGuard, userPermissionGuard],
        data: {
          permissions: permissions.standaloneProfiles,
        },
      },
      {
        path: 'user-tokens',
        title: 'User tokens',
        loadChildren: () => import('./pages/user-token/user-token.module').then((m) => m.UserTokenModule),
        canActivate: [authGuard, userPermissionGuard],
        data: { permissions: permissions.userTokens },
      },
      {
        path: 'informing-user',
        title: 'Informing user',
        canActivate: [authGuard, userPermissionGuard],
        data: { permissions: permissions.informingUser },
        loadChildren: () => import('./pages/informing-user/informing-user.module').then((m) => m.InformingUserModule),
      },
    ],
  },
  {
    path: '',
    canActivate: [nonAuthGuard],
    loadChildren: () => import('./layouts/simple-layout/simple-layout.module').then((m) => m.SimpleLayoutModule),
  },
  {
    path: 'not-found',
    loadComponent: () => import('@tk/shared/page-not-found').then((c) => c.PageNotFoundComponent),
    pathMatch: 'full',
  },
  {
    path: '**',
    loadComponent: () => import('@tk/shared/page-not-found').then((c) => c.PageNotFoundComponent),
    pathMatch: 'full',
  },
];
