<div class="toast" [ngClass]="toast.type">
  <svg-icon *ngIf="toast.type" class="toast__icon" [key]="icon"></svg-icon>
  <div class="toast__message line-clamp line-clamp--3">{{ toast.message.trim() }}</div>
  <div
    class="divider divider--vertical divider--full"
    [ngClass]="toast.type === 'notice' ? 'divider--dark' : 'divider--light'"
  ></div>
  <button
    tk-close-button
    [variant]="toast.type === 'notice' ? 'primary' : 'inverse'"
    aria-label="Close"
    (click)="close()"
  ></button>
</div>
