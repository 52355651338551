import { appHardDriveIcon } from './hard-drive';
import { appMouseIcon } from './mouse';
import { appPlayCircleIcon } from './play-circle';
import { appPlayIcon } from './play';
import { appPower01Icon } from './power-01';
import { appStopCircleIcon } from './stop-circle';
import { appStopIcon } from './stop';
import { appVolumeMaxIcon } from './volume-max';
import { appVolumeXIcon } from './volume-x';
export const mediaIcons = [appHardDriveIcon, appMouseIcon, appPlayCircleIcon, appPlayIcon, appPower01Icon, appStopCircleIcon, appStopIcon, appVolumeMaxIcon, appVolumeXIcon];
