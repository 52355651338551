import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SvgIconComponent } from '@ngneat/svg-icon';

import { TkCloseButtonComponent } from '@itera-ui-kit/tk-buttons/close-button';
import { Toast } from '@shared/models/toast.model';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SvgIconComponent, NgClass, NgIf, TkCloseButtonComponent],
})
export class ToastComponent implements OnInit {
  // TODO strictPropertyInitialization we need to delete and properly initalize this property
  @Input() toast: Toast = undefined!;

  @Output() dismiss = new EventEmitter<void>();
  // TODO strictPropertyInitialization we need to delete and properly initalize this property
  timeoutToDismiss: ReturnType<typeof setTimeout> = undefined!;

  get icon(): string {
    switch (this.toast?.type) {
      case 'positive':
        return 'general:check';
      case 'notice':
        return 'taikun:exclamation-mark';
      case 'negative':
        return 'alerts:alert-triangle';
      case 'informative':
        return 'taikun:info-mark';
    }
  }

  ngOnInit(): void {
    // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
    this.timeoutToDismiss = setTimeout(() => this.dismiss.emit(), this.toast.ttl! * 1000);
  }

  close() {
    clearTimeout(this.timeoutToDismiss);
    this.dismiss.emit();
  }
}
